<template>
	<div href="#" class="relative block w-100 p-40 mb-40 border-light border-dashed border-width-3 rounded hover:background-ultra-light-grey-300" :style="src && `padding-bottom: 54.5%;`">
		<template v-if="src">
			<img :src="src" alt="" />

			<div class="remove-image" @click="deleteFile"></div>
		</template>

		<template v-if="!loading && !src">
			<h1 class="mb-10">Pass ella koyrikort</h1>

			<p class="color-grey mb-75">Viðheft eitt gyldugt pass ella koyrikort</p>

			<div class="flex flex-column align-center">
				<p class="upload flex flex-column align-center mb-30 color-grey">Viðheft mynd</p>
			</div>

			<div class="flex flex-column align-center">
				<div class="small-text color-red mt-10 px-5" v-if="error">Tú kanst einans uploada .jpg, .png ella .pdf-fílur</div>
			</div>

			<input type="file" id="passport" class="absolute top left w-100 h-100 opacity-0 cursor-pointer" @change="upload" />
		</template>

		<template v-if="loading">
			<div class="flex justify-center">
				<i class="fas fa-spinner fa-spin fa-2x color-blue"></i>
			</div>
		</template>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	name: 'UploadIdentification',

	props: ['value', 'agreementUuid', 'customerUuid', 'ownerName'],

	data() {
		return {
			error: false,

			src: null,

			file: null,

			loading: false,
		};
	},

	mounted() {
		//

		if (!this.value) {
			return;
		}

		this.src = this.value.url;
		this.file = this.value;
	},

	destroyed() {
		//
	},

	methods: {
		hideImage() {
			this.src = '';

			this.$emit('input', null);
		},

		upload() {
			var formData = new FormData();

			var file = document.querySelector('#passport');

			file = file.files[0];

			const extension = file.name.substring(file.name.lastIndexOf('.') + 1);
			const fileName = `samleikaprogv_${this.ownerName.toLowerCase().replace(/ /g, '-')}.${extension}`;

			formData.append('file', new File([file], fileName, { type: file.type }));

			if (this.agreementUuid) {
				formData.append('agreement_uuid', this.agreementUuid);
			}

			if (this.customerUuid) {
				formData.append('customer_uuid', this.customerUuid);
			}

			this.loading = true;

			const token = this.$route.query.token;

			axios
				.post(`/agreement-links/${token}/file`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					this.file = response.data;

					this.src = URL.createObjectURL(file);

					this.$emit('input', this.file);
				})
				.catch((error) => {
					console.error('Upload error', error);

					this.error = true;

					this.hideImage();
				})
				.finally(() => {
					this.loading = false;
				});
		},

		deleteFile() {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			const token = this.$route.query.token;

			axios
				.delete(`/agreement-links/${token}/file/${this.file.uuid}`)
				.then(() => {
					this.file = '';

					this.hideImage();
				})
				.catch((error) => {
					console.error('Delete error', error);
				});
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../scss/mixins';

img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	object-fit: cover;
}

.remove-image {
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 40px;

	display: flex;
	justify-content: center;
	align-items: center;

	background: var(--color-red);

	transform: translate(33%, -33%);

	border-radius: 50%;

	cursor: pointer;

	&::after {
		content: '';
		@include mask('../assets/icon-plus.svg', 18px 18px);

		display: block;
		width: 18px;
		height: 18px;

		background: white;

		transform: rotate(45deg);

		pointer-events: none;
	}
}
</style>
