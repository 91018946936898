<template>
	<v-overlay :visible="visible" @close="$emit('close')">
		<div class="flex space-between align-center mb-50">
			<div>
				<h1 class="mb-10">Nýggja konto</h1>

				<p class="color-grey">Legg eina nýggja banka konto afturat kundanum.</p>
			</div>

			<div class="overlay-close" @click.prevent="$emit('close')"></div>
		</div>

		<div>
			<div class="flex flex-wrap mb-20">
				<div class="flex align-center mb-10">
					<input type="radio" value="9181" v-model="form.registrationNumber" id="betri" />

					<label class="pl-10 mr-20 cursor-pointer" for="betri">Betri</label>
				</div>

				<div class="flex align-center mb-10">
					<input type="radio" value="9865" v-model="form.registrationNumber" id="ns" />

					<label class="pl-10 mr-20 cursor-pointer" for="ns">Norðoyar Sparikassi</label>
				</div>

				<div class="flex align-center mb-10">
					<input type="radio" value="6460" v-model="form.registrationNumber" id="banknordik" />

					<label class="pl-10 mr-20 cursor-pointer" for="banknordik">BankNordik</label>
				</div>

				<div class="flex align-center mb-10">
					<input type="radio" value="9870" v-model="form.registrationNumber" id="ss" />

					<label class="pl-10 mr-20 cursor-pointer" for="ss">Suðuroyar Sparikassi</label>
				</div>
			</div>

			<div class="grid grid-1-3 grid-gap-20 mb-10">
				<InputGroup class="disabled" v-model="form.registrationNumber" label="Reg. nr." :disabled="true" />

				<InputGroup v-model="form.accountNumber" label="Kontonr." />
			</div>
		</div>

		<div class="flex justify-end">
			<div class="btn inline-block" :class="{ disabled: !isValid }" @click="save">Stovna</div>
		</div>
	</v-overlay>
</template>

<script>
import axios from 'axios';

import VOverlay from '@/components/VOverlay.vue';
import InputGroup from '@/components/InputGroup.vue';

export default {
	name: 'AddBankOverlay',

	props: {
		visible: Boolean,
		customerId: Number,
		throughAgreementLink: Boolean,
	},

	components: {
		VOverlay,
		InputGroup,
	},

	data() {
		return {
			form: {
				registrationNumber: '',
				accountNumber: '',
			},
			registrationNumberError: '',
			accountNumberError: '',
			errorMessage: '',
		};
	},

	computed: {
		isValid() {
			return this.form.registrationNumber && this.form.accountNumber && this.form.registrationNumber.length == 4;
		},
	},

	methods: {
		save() {
			if (!this.isValid) {
				if (!this.form.registrationNumber.length == 4) {
					this.registrationNumberError = 'Reg nr. skal vera 4 tøl';
				}

				return;
			}

			this.$emit('close');

			const token = this.$route.query.token;
			const url = this.throughAgreementLink ? `/agreement-links/${token}/bank-account` : `/bank-accounts`;

			axios
				.post(url, {
					registrationNumber: this.form.registrationNumber,
					accountNumber: this.form.accountNumber,
					customerId: this.customerId,
				})
				.then((response) => {
					this.$emit('add', response.data);
				})
				.catch((error) => {
					console.log('Error adding bank account', error);
				});
		},
	},
};
</script>
