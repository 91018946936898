<template>
	<div class="mb-30">
		<label class="mb-10">{{ label }}</label>

		<div class="flex">
			<label class="mr-15 cursor-pointer">
				<input tabindex="-1" type="radio" v-model="selected" value="1" :name="name ? name : label" class="cursor-pointer" />

				Ja
			</label>

			<label class="cursor-pointer">
				<input tabindex="-1" type="radio" v-model="selected" value="0" :name="name ? name : label" class="cursor-pointer" />

				Nei
			</label>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SideBar',

	props: ['value', 'label', 'name'],

	data() {
		return {
			selected: '',
		};
	},

	mounted() {
		if (this.value) {
			this.selected = '1';
		}

		if (typeof this.value == 'boolean') {
			this.selected = this.value ? '1' : '0';
		}
	},

	watch: {
		selected: function (value) {
			this.$emit('input', value == '1');
		},

		value: function (value) {
			if (this.value) {
				this.selected = '1';
			}

			if (typeof value == 'boolean') {
				this.selected = value ? '1' : '0';
			}
		},
	},
};
</script>
